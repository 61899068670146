import { useEffect, useRef, useState } from "react";
import "./WheelsController.css";
import {
  getWheelMaterialId,
  updateWheels,
} from "../../../services/car.service";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectComponent } from "../../select/Select";
import { mockWheelsImages } from "../../../mocks/images.mock";
import { UUID } from "../../../types/types";

export const WheelsControllerComponent = () => {
  const [selectedMaterialId, setSelectedMaterialId] = useState("");
  const [materialList, setMaterialList] = useState<any>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    async function setStateAsync() {
      setSelectedMaterialId(await getWheelMaterialId());
    }

    setMaterialList(mockWheelsImages);

    setStateAsync();

    if (contentRef.current) {
      contentRef.current.style.maxHeight = isExpanded
        ? `${contentRef.current.scrollHeight}px`
        : "0";
    }
  }, [isExpanded]);

  const handleColorChange = (value: UUID) => {
    const newMaterialId = value;
    updateWheels(newMaterialId);
    setSelectedMaterialId(newMaterialId);
  };

  return (
    <article className={`${isExpanded ? "active " : ""} wheels_accordion`}>
      <header onClick={() => setIsExpanded(!isExpanded)}>
        <h4>Wheels</h4>
        <FontAwesomeIcon className={`icon ${isExpanded ? "rotate" :''}`} icon={faChevronDown} size="xs" />
      </header>

      <section className="wheels_accordion_content" ref={contentRef}>
        <div className="select_div">
          <SelectComponent
            imageOptions={materialList}
            onChange={handleColorChange}
            value={selectedMaterialId}
          />
        </div>
      </section>
    </article>
  );
};
