import { MouseEventHandler, ReactNode } from "react";
import "./Button.css";
interface ButtonComponentProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  children?: ReactNode;
  color?: "primary" | "secondary";
}

export const ButtonComponent = (props: Readonly<ButtonComponentProps>) => {
  return (
    <button
      className={`button ${props.color || "primary"} ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
