export const PUBLIC_TOKEN = "public_aP-EMNzdnkCtTaL8";
export const ADMIN_USER_ID = "061af42a-5b1c-4e97-a5e5-4b9439e2d659";
export const SCENE_UUID = "3f72eb9c-00ed-4d2d-8084-88e91d6741ab";
export const API_KEY = "6f6b42db-0483-4502-ad9d-50042025c5d1.VgjN6h_9OTPjvLMreT1_eSAv-0Gn-vwRCbYAzwJDxs0";
export const API_URL = "https://api.3dverse.com/app/v1/";

/**
 * CAMERA SETTINGS
 */
export const DEFAULT_CAMERA_POSITION = [2.786005, 1.210191, 4.745349];
export const DEFAULT_CAMERA_ORIENTATION = [-5.795824, 30.955244, -0.000009];

/**
 * ENTITIES
 * */
export const CAR_LIGHTS_ENTITY = "8e4b3628-32fa-420e-a045-46fca7d515aa";

export const WINDOW_ENTITY_LIST = [
  "216f9f70-17f2-4c2e-90d3-7d9f5ce75a59",
  "75bd2545-7849-4c04-b313-21ff8e4ae3a3",
  "f5f6ffed-3c67-40b3-a9de-4baddc20c99f",
];

export const BODY_ENTITY_LIST = [
  "26dd8290-ffc1-48f2-aa97-5f2810cc9595",
  "042fa83c-b110-4dee-8e97-5ce7c8021a76",
  "73c0b269-5ef4-48a9-8b3b-4174ca018998",
  "484a8836-26de-4f6f-8a5e-ba085d5cf86e",
  "9f0c6fc5-ee47-4e40-b878-f851273372a4",
  "3f8cc378-c764-4163-888c-af00d7a16005",
  "3225fa5f-891e-4e9c-a1d3-15c31cb36c3d",
  "3c98c63e-cd02-4b00-9250-41f1df15d1ad",
  "9839baf7-df01-4522-9dfb-9666ea30f2e7",
  "aa15e574-f2bc-4762-9c7c-14d897a532b5",
  "bda49b7b-5738-44e1-a6cf-46176688fa0c",
  "b9f8a070-2bb7-463c-8581-54517c201dac",
];

export const WHEEL_ENTITY_LIST = [
  "0c03c27b-1022-416d-9739-8da279553baf",
  "2d9ec889-8aa8-469a-8bd7-f07eaf49c32a",
  "f76ad42f-257d-48ce-8246-9e518a0e2f55",
  "8211972b-4d40-4c42-8c2a-fe7e45983480",
];

export const TRIM_ENTITY_LIST = [
  "8031f89e-0987-466c-85aa-090b52c65e68",
  "c9da9e68-e0a5-47aa-bcb1-8bd5f6b78ab7",
  "8a10555e-c507-4252-adc6-f6b9a42fc8e9",
];

/**
 * MATERIALS
 */
export const TINTED_WINDOWS_MATERIAL = "dac0317b-e0ec-4886-9ae7-f63b24c63ba8";
export const NON_TINTED_WINDOWS_MATERIAL =
  "ddc5afd7-c79a-42e2-bdd0-8c772c561242";

/**
 * ANIMATIONS
 */
export const SUN_GOES_UP = "cb8647bc-7c0d-43f9-afab-8662d6ac30ca";
export const SUN_GOES_DOWN = "1f95f99c-d2b5-4866-839f-f8514eed90aa";
export const CLOSE_DOORS = "ddfe57b5-bc84-4f59-bcee-2b12c489d33f";
export const OPEN_DOORS = "87ef31df-3354-4749-aa8b-42ae54687856";
