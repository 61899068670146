import { useScript } from "@uidotdev/usehooks";
import { useCallback, useEffect, useRef } from "react";
import "./Canvas.css";
import { setup } from "../../helpers/setup";
import { API_KEY, SCENE_UUID } from "../../config";
import { ShareButtonComponent } from "../share-button/ShareButton";
import { DayNightButtonComponent } from "../day-night-button/DayNightButton";
import { DoorsButton } from "../doors-button/DoorsButton";
import { TravelButtonComponent } from "../travel-button/TravelButton";
import { EditCarComponent } from "../sidebar/EditCar";
import { generateToken } from "../../services/auth.service";
import { setApiKey } from "@3dverse/api";

export const CanvasComponent = () => {
  const canvasRef = useRef(null);
  const script = useScript(
    `https://cdn.3dverse.com/legacy/sdk/latest/SDK3DVerse.js`,
    {
      removeOnUnmount: false,
    }
  );
  const scriptClient = useScript(
    `https://cdn.3dverse.com/legacy/sdk/stable/SDK3DVerse_ClientDisplay_Ext.js`,
    {
      removeOnUnmount: false,
    }
  );

  const initApp = useCallback(async () => {

    setApiKey(API_KEY);
    const token = (await generateToken()).data;

    await SDK3DVerse.joinOrStartSession({
      userToken: token.user_token,
      sceneUUID: SCENE_UUID,
      canvas: document.getElementById("display-canvas"),
      viewportProperties: {
        defaultControllerType: SDK3DVerse.controller_type.orbit,
      },
    });
    setup();
  }, []);

  useEffect(() => {
    if (script === "ready" && scriptClient === "ready") {
      initApp();
    }
  }, [initApp, script, scriptClient]);

  return (
    <div className="canvas-wrapper">
      <canvas id="display-canvas" className="canvas" ref={canvasRef}></canvas>
      <div className="edit-car-menu">
        <EditCarComponent />
      </div>
      <div className="buttons-wrapper">
        <TravelButtonComponent />
        <DayNightButtonComponent />
        <DoorsButton />
      </div>
      <div className="share-button">
        <ShareButtonComponent />
      </div>
    </div>
  );
};

