import beigeUrl from "../assets/paints/beige.png";
import blueChromeUrl from "../assets/paints/blue-chrome.png";
import blueMatUrl from "../assets/paints/blue-mat.png";
import blueMetallicUrl from "../assets/paints/blue-metallic.png";
import burgundyChromeUrl from "../assets/paints/burgundy-chrome.png";
import burgundyMetallicUrl from "../assets/paints/burgundy-metallic.png";
import kakiChromeUrl from "../assets/paints/kaki-chrome.png";
import kakiMetallicUrl from "../assets/paints/kaki-metallic.png";
import reddishBrownUrl from "../assets/paints/reddish-brown.png";

import aluminumUrl from "../assets/wheels/aluminum.png";
import chromeUrl from "../assets/wheels/chrome.png";
import blackUrl from "../assets/wheels/black.png";
import vermeilUrl from "../assets/wheels/vermeil.png";
import blueUrl from "../assets/wheels/blue-metallic.png";

import blackWoodUrl from "../assets/trims/black-wood.png";
import brightWoodUrl from "../assets/trims/bright-wood.png";
import redWoodUrl from "../assets/trims/red-wood.png";
import darkWoodUrl from "../assets/trims/dark-wood.png";

export const mockPaintsImages = [
  {
    url: blueChromeUrl,
    alt: "Blue chrome",
    materialId: "2628412d-4624-42a3-b24d-891f7da597ec",
  },
  {
    url: blueMetallicUrl,
    alt: "Blue metallic",
    materialId: "48293b05-5e23-41e9-91ba-bfe3ed072c0f",
  },
  {
    url: blueMatUrl,
    alt: "Blue mat",
    materialId: "2b2f4e0e-14fe-41ab-b09a-98bcc041ec7b",
  },
  {
    url: burgundyChromeUrl,
    alt: "Burgundy chrome",
    materialId: "4c71bcf2-3051-460b-837f-e500a333d7a7",
  },
  {
    url: burgundyMetallicUrl,
    alt: "Burgundy metallic",
    materialId: "b693d865-da8a-4f17-8b48-0ae10e8999f2",
  },
  {
    url: reddishBrownUrl,
    alt: "Reddish brown",
    materialId: "5bb12c13-54df-4c91-a852-f76adcc380d3",
  },
  {
    url: kakiChromeUrl,
    alt: "Kaki chrome",
    materialId: "2550d8a4-dc6e-4a35-bba8-9802590738f2",
  },
  {
    url: kakiMetallicUrl,
    alt: "Kaki metallic",
    materialId: "a3a68683-e242-44ed-95e5-0c67f8db90e0",
  },
  {
    url: beigeUrl,
    alt: "Beige",
    materialId: "aba124ac-43eb-4e42-ba47-d4d311337ea5",
  },
];

export const mockWheelsImages = [
  {
    url: aluminumUrl,
    alt: "Aluminum",
    materialId: "aa3eeedf-1028-486a-adf6-860f6a5ca845",
  },
  {
    url: chromeUrl,
    alt: "Chrome",
    materialId: "2d8c3056-fc7c-48a3-a7ba-da5f8d784a4a",
  },
  {
    url: blackUrl,
    alt: "Black",
    materialId: "a06be201-fe1c-4d16-8eaa-6b76e378af84",
  },
  {
    url: blueUrl,
    alt: "Blue",
    materialId: "72a12a15-4f05-45c0-ad50-c146e06e570c",
  },
  {
    url: vermeilUrl,
    alt: "Vermeil",
    materialId: "a2ad82f9-b6d5-4a7a-9211-e84e2ca59504",
  },
];

export const mockTrimImages = [
  {
    url: blackWoodUrl,
    alt: "Black wood",
    materialId: "b4467c6d-0c58-49f7-808b-9cdbbc5fdadf",
  },
  {
    url: brightWoodUrl,
    alt: "Bright wood",
    materialId: "f36eea2f-a773-4100-a02d-a8f27890f7d0",
  },
  {
    url: darkWoodUrl,
    alt: "Dark wood",
    materialId: "163b39c2-ca21-4732-8b69-dd6e4da5411b",
  },
  {
    url: redWoodUrl,
    alt: "Red wood",
    materialId: "a0c40d17-a0c9-4351-a7b9-7175408c7d5b",
  },
];
