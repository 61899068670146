import { useState } from "react";
import { CAR_LIGHTS_ENTITY, SUN_GOES_DOWN, SUN_GOES_UP } from "../../config";
import { SvgIconButtonComponent } from "../svg-icon-button/SvgIconButton";
import sunset from "../../assets/svg-icons/sunset.svg";
import sun from "../../assets/svg-icons/sunny.svg";

export const DayNightButtonComponent = () => {
  const [isDay, setIsDay] = useState(true);

  const handleClick = async () => {
    const carLightsEntity = await SDK3DVerse.engineAPI.findEntitiesByEUID(
      CAR_LIGHTS_ENTITY
    );
    if (isDay) {
      SDK3DVerse.engineAPI.playAnimationSequence(SUN_GOES_DOWN, {
        seekOffset: 0,
      });
      carLightsEntity[0].setVisibility(true);
    } else {
      SDK3DVerse.engineAPI.playAnimationSequence(SUN_GOES_UP, {
        seekOffset: 0,
      });
      carLightsEntity[0].setVisibility(false);
    }
    setIsDay(!isDay);
  };

  return (
    <SvgIconButtonComponent
      onClick={handleClick}
      src={isDay ? sunset : sun}
      alt={isDay ? "sunset" : "sun"}
      tooltip={isDay ? "Enable night mode" : "Enable day mode"}
    />
  );
};
