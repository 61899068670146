export const WHEEL = "e92_wheel";
export const PAINT = "e92_paint";
export const TRIM = "e92_trim";

export const INSIDE = "inside";
export const OUTSIDE = "outside";

export const ACTION_UPDATE_WINDOWS = "windows";
export const ACTION_UPDATE_WHEELS = "wheels";
export const ACTION_UPDATE_BODY = "body";
export const ACTION_UPDATE_TRIM = "trim";
