import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UUID } from "../../types/types";
import "./Select.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface Image {
  url: string;
  alt: string;
  materialId: UUID;
}

interface SelectProps {
  imageOptions: Image[];
  value: UUID;
  onChange: (value: string) => void;
}

export const SelectComponent = (props: Readonly<SelectProps>) => {
  const handleClick = (value: string) => {
    props.onChange(value);
  };
  return (
    <div className="select_container">
      {props.imageOptions.map((option, index) => (
        <div
          key={index}
          className={`option ${
            props.value === option.materialId ? " active" : ""
          }`}
          onClick={() => handleClick(option.materialId)}
        >
          {props.value === option.materialId && (
            <div className="option_badge">
              <FontAwesomeIcon icon={faCheck} />
            </div>
          )}
          <img src={option.url} alt={option.alt} className="option_image" />
        </div>
      ))}
    </div>
  );
};
