import React, { ReactNode } from "react";
import "./Overlay.css";
interface OverlayProps {
  onClose: () => void;
  children: ReactNode;
}

const OverlayComponent: React.FC<OverlayProps> = ({ onClose, children }) => {
  return (
    <div className="overlay-container">
      <div className="overlay" onClick={onClose}></div>
      <div className="overlay-content">
        <div className="body">{children}</div>
      </div>
    </div>
  );
};

export default OverlayComponent;
