import { useEffect, useRef, useState } from "react";
import { getBodyMaterialId, updateBody } from "../../../services/car.service";
import "./BodyController.css";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mockPaintsImages } from "../../../mocks/images.mock";
import { SelectComponent } from "../../select/Select";
import { UUID } from "../../../types/types";

export const BodyControllerComponent = () => {
  const [selectedMaterialId, setSelectedMaterialId] = useState("");
  const [materialList, setMaterialList] = useState<any>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    async function setStateAsync() {
      setSelectedMaterialId(await getBodyMaterialId());
    }

    setMaterialList(mockPaintsImages);

    setStateAsync();

    if (contentRef.current) {
      contentRef.current.style.maxHeight = isExpanded
        ? `${contentRef.current.scrollHeight}px`
        : "0";
    }
  }, [isExpanded]);

  const handleColorChange = (value: UUID) => {
    const newMaterialId = value;
    updateBody(newMaterialId);
    setSelectedMaterialId(newMaterialId);
  };

  return (
    <article className={`${isExpanded ? "active " : ""} body_accordion`}>
      <header onClick={() => setIsExpanded(!isExpanded)}>
        <h4>Body</h4>
        <FontAwesomeIcon className={`icon ${isExpanded ? "rotate" :''}`} icon={faChevronDown} size="xs" />
      </header>

      <section className="body_accordion_content" ref={contentRef}>
        <div className="select_div">
          <SelectComponent
            imageOptions={materialList}
            onChange={handleColorChange}
            value={selectedMaterialId}
          />
        </div>
      </section>
    </article>
  );
};
