import { generateUserToken } from "@3dverse/api";
import { ADMIN_USER_ID } from "../config";

export const generateToken = async () => {
  return await generateUserToken({
    user_id: ADMIN_USER_ID,
    scope: "manage",
    ttl: "1d",
  });
};
