import { useState } from "react";
import { CLOSE_DOORS, OPEN_DOORS } from "../../config";
import { SvgIconButtonComponent } from "../svg-icon-button/SvgIconButton";
import openDoors from "../../assets/svg-icons/open-doors.svg"
import openDoorsWhite from "../../assets/svg-icons/open-doors-white.svg"

export const DoorsButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (isOpen) {
      SDK3DVerse.engineAPI.playAnimationSequence(CLOSE_DOORS, {
        seekOffset: 0,
      });
    } else {
      SDK3DVerse.engineAPI.playAnimationSequence(OPEN_DOORS, { seekOffset: 0 });
    }
    setIsOpen(!isOpen);
  };

  return (
    <SvgIconButtonComponent
      onClick={handleClick}
      src={isOpen ? openDoorsWhite : openDoors}
      alt={isOpen ? "close car doors" : "open car doors"}
      tooltip={isOpen ? "Close the doors" : "Open the doors"}
      active={isOpen}
    />
  );
};
