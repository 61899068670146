import { listAssets } from "@3dverse/api";
import { CarElement, Viewpoint } from "../types/types";
import { ComponentMap } from "../types/3dverse.type";

const renameAsset = (name: string, carElement: CarElement) => {
  const arrStr = name.split(carElement)[1].split("_").slice(1);
  let str = "";
  arrStr.forEach((arr, i) => {
    str +=
      i === 0
        ? arr.charAt(0).toUpperCase() + arr.slice(1)
        : " " + arr.toLowerCase();
  });
  return str;
};

export const getMaterial = async (assetName: CarElement) => {
  return (
    await listAssets({
      filter: { asset_type: "material", asset_name: assetName },
    })
  ).data.materials?.map((asset) => {
    return {
      asset_id: asset.asset_id,
      name: renameAsset(asset.name, assetName),
    };
  });
};

export const travelToViewpoint = async (viewpoint: Viewpoint) => {
  const viewport = await SDK3DVerse.engineAPI.cameraAPI.getViewports()[0];

  const componentFilter = {
    mandatoryComponents: ["label"],
  };

  const entities = await SDK3DVerse.engineAPI.findEntitiesByComponents(
    componentFilter
  );

  const labelComponent = entities
    .map((entity: any) => entity.components)
    .find((component: ComponentMap) =>
      component.debug_name.value.includes(viewpoint)
    ).label;

  await SDK3DVerse.engineAPI.cameraAPI.travel(
    viewport,
    labelComponent.camera.slice(0, 3),
    labelComponent.camera.slice(3, 7),
    labelComponent.speed
  );
};
