import { MouseEventHandler, useState } from "react";
import "./SvgIconButton.css";

interface SvgIconButtonComponentProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  src: string;
  alt: string;
  tooltip?: string;
  active?: boolean;
}

export const SvgIconButtonComponent = (
  props: Readonly<SvgIconButtonComponentProps>
) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      className={`button_for_svg ${props.active ? "active" : ""}`}
      onClick={props.onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={props.src} alt={props.alt} />
      {isHovered && <div className="tooltip">{props.tooltip}</div>}
    </button>
  );
};
