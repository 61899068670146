import "./App.css";
import { CanvasComponent } from "./components/canvas/Canvas";

function App() {

  return (
    <div className="App">
      <CanvasComponent />
    </div>
  );
}

export default App;
