import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonComponent } from "../button/Button";
import { faLink, faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import OverlayComponent from "../overlay/Overlay";
import "./ShareButton.css";
import { QRCodeComponent } from "../qr-code/QRCode";

export const ShareButtonComponent = () => {
  const currentUrl = window.location.href;
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentUrl);
  };
  const toggleOverlay = () => {
    setIsOverlayOpen(!isOverlayOpen);
  };
  return (
    <>
      <ButtonComponent onClick={toggleOverlay} color="secondary" className="share_button">
        <FontAwesomeIcon icon={faShareFromSquare} size="lg"/>
      </ButtonComponent>
      {isOverlayOpen && (
        <OverlayComponent onClose={toggleOverlay}>
          <div className="share-infos">
            <h3>Access the scene</h3>
            <ButtonComponent color="secondary" onClick={handleCopyLink}>
              <FontAwesomeIcon icon={faLink} />
              &nbsp; Copy link
            </ButtonComponent>
            <h5>or flash the QR code :</h5>
            <QRCodeComponent url={currentUrl} />
          </div>
        </OverlayComponent>
      )}
    </>
  );
};
