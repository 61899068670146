import { CAR_LIGHTS_ENTITY, SUN_GOES_UP } from "../config";
import { OUTSIDE } from "../constants";
import { travelToViewpoint } from "../services/3dverse.service";
import { UUID } from "crypto";

export const setup = async () => {
  const clientDisplayEX = await SDK3DVerse.installExtension(
    SDK3DVerse_ClientDisplay_Ext
  );
  const clientAvatarContent = await fetch("public/client-avatar.svg").then(
    (res) => res.text()
  );
  clientDisplayEX.showClientAvatars({
    radius: 80,
    getClientAvatarSrc({ color }: { color: string }) {
      const svgContent = clientAvatarContent
        .replace(/FG_COLOR/g, color)
        .replace(/BG_COLOR/g, "#ffffff");
      const url = `data:image/svg+xml,${encodeURIComponent(svgContent)}`;
      return url;
    },
    getClientDisplayName({ clientUUID }: { clientUUID: UUID }) {
      return `User ${clientUUID}`;
    },
  });

  const entity = await SDK3DVerse.engineAPI.findEntitiesByEUID(
    CAR_LIGHTS_ENTITY
  );
  entity[0].setVisibility(false);

  SDK3DVerse.engineAPI.playAnimationSequence(SUN_GOES_UP, {
    seekOffset: 0,
  });
  SDK3DVerse.engineAPI.cameraAPI.updateControllerSettings({ speed: 3.0 });
  travelToViewpoint(OUTSIDE);
};
