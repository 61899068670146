import {
  BODY_ENTITY_LIST,
  NON_TINTED_WINDOWS_MATERIAL,
  TINTED_WINDOWS_MATERIAL,
  TRIM_ENTITY_LIST,
  WHEEL_ENTITY_LIST,
  WINDOW_ENTITY_LIST,
} from "../config";

import { UUID } from "../types/types";

const updateMaterialEntities = async (entityList: any, materialRef: any) => {
  const entities = await Promise.all(entityList).then(function (results) {
    return results.map((result) => result[0]);
  });

  await entities.forEach((entity) => {
    entity.setComponent("material_ref", { value: materialRef });
  });
  SDK3DVerse.engineAPI.overrideComponent(entities, "material_ref");
};

const getMaterialIdForEntity = (entity: any) => {
  let materialId;
  materialId = entity.getComponent("material_ref")?.value;
  return materialId;
};

export const updateWindows = (isTinted: boolean) => {
  const materialRef: UUID = isTinted
    ? TINTED_WINDOWS_MATERIAL
    : NON_TINTED_WINDOWS_MATERIAL;

  const windowEntityList = WINDOW_ENTITY_LIST.map((windowEntity) =>
    SDK3DVerse.engineAPI.findEntitiesByEUID(windowEntity)
  );

  updateMaterialEntities(windowEntityList, materialRef);
};

export const getWindowsMaterialId = async () => {
  const entity = (
    await SDK3DVerse.engineAPI.findEntitiesByEUID(WINDOW_ENTITY_LIST[0])
  )[0];

  return getMaterialIdForEntity(entity);
};

export const updateBody = (materialRef: any) => {
  const bodyEntityList = BODY_ENTITY_LIST.map((bodyEntity) =>
    SDK3DVerse.engineAPI.findEntitiesByEUID(bodyEntity)
  );
  updateMaterialEntities(bodyEntityList, materialRef);
};

export const getBodyMaterialId = async () => {
  const entity = (
    await SDK3DVerse.engineAPI.findEntitiesByEUID(BODY_ENTITY_LIST[0])
  )[0];

  return getMaterialIdForEntity(entity);
};

export const updateWheels = (materialRef: any) => {
  const wheelsEntityList = WHEEL_ENTITY_LIST.map((wheelsEntity) =>
    SDK3DVerse.engineAPI.findEntitiesByEUID(wheelsEntity)
  );
  updateMaterialEntities(wheelsEntityList, materialRef);
};

export const getWheelMaterialId = async () => {
  const entity = (
    await SDK3DVerse.engineAPI.findEntitiesByEUID(WHEEL_ENTITY_LIST[0])
  )[0];

  return getMaterialIdForEntity(entity);
};

export const updateTrim = (materialRef: any) => {
  const trimEntityList = TRIM_ENTITY_LIST.map((trimEntity) =>
    SDK3DVerse.engineAPI.findEntitiesByEUID(trimEntity)
  );
  updateMaterialEntities(trimEntityList, materialRef);
};

export const getTrimMaterialId = async () => {
  const entity = (
    await SDK3DVerse.engineAPI.findEntitiesByEUID(TRIM_ENTITY_LIST[0])
  )[0];

  return getMaterialIdForEntity(entity);
};
