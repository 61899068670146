import "./EditCar.css";
import { WindowControllerComponent } from "./window-controller/WindowController";
import { BodyControllerComponent } from "./body-controller/BodyController";
import { WheelsControllerComponent } from "./wheels-controller/WheelsController";
import { TrimControllerComponent } from "./trim-controller/TrimController";

export const EditCarComponent = () => {
  return (
    <div className="edit_car">
      <h2>Edit car</h2>
      <section>
        <hr />
        <div className="inputs">
          <WindowControllerComponent />
          <WheelsControllerComponent />
          <BodyControllerComponent />
          <TrimControllerComponent />
        </div>
      </section>
    </div>
  );
};
