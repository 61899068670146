import { useEffect, useRef, useState } from "react";
import "./WindowController.css";
import {
  getWindowsMaterialId,
  updateWindows,
} from "../../../services/car.service";
import { TINTED_WINDOWS_MATERIAL } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export const WindowControllerComponent = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    async function asyncSetIsChecked() {
      setIsChecked((await getWindowsMaterialId()) === TINTED_WINDOWS_MATERIAL);
    }
    asyncSetIsChecked();

    if (contentRef.current) {
      contentRef.current.style.maxHeight = isExpanded
        ? `${contentRef.current.scrollHeight}px`
        : "0";
    }
  }, [isExpanded]);

  const handleChange = (e: any) => {
    updateWindows(e.target.checked);
    setIsChecked(!isChecked);
  };

  return (
    <article className={`${isExpanded ? "active " : ""} windows_accordion`}>
      <header onClick={() => setIsExpanded(!isExpanded)}>
        <h4>Windows</h4>
        <FontAwesomeIcon className={`icon ${isExpanded ? "rotate" :''}`} icon={faChevronDown} size="xs" />
      </header>

      <section className="windows_accordion_content" ref={contentRef}>
        <div className="checkbox-div">
          <input
            checked={isChecked}
            id="front-window-tinted-checkbox"
            type="checkbox"
            onChange={handleChange}
          />
          <label htmlFor="front-window-tinted-checkbox">Tinted windows</label>
        </div>
      </section>
    </article>
  );
};
