import { useState } from "react";
import { INSIDE, OUTSIDE } from "../../constants";
import { travelToViewpoint } from "../../services/3dverse.service";
import { SvgIconButtonComponent } from "../svg-icon-button/SvgIconButton";
import carSeatIcon from "../../assets/svg-icons/car-seat.svg";
import carIcon from "../../assets/svg-icons/car.svg";

export const TravelButtonComponent = () => {
  const [isInside, setIsInside] = useState(false);

  const handleClick = () => {
    travelToViewpoint(isInside ? OUTSIDE : INSIDE);
    setIsInside(!isInside);
  };

  return (
    <SvgIconButtonComponent
      onClick={handleClick}
      src={isInside ? carIcon : carSeatIcon}
      alt={isInside ? "car" : "car seat"}
      tooltip={isInside ? "Travel outside the car" : "Travel inside the car"}
    />
  );
};
